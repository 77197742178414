typeof window !== "undefined" ? require("boxicons") : null;
import PropTypes from "prop-types";
import React, { useState } from "react";
import Whatsapp from "../assets/icons/whatsappp.png";
import CookieConfirmation from "./CookieConfirmation";
import Footer from "./Footer";
import PageHeader from "./PageHeader";

function Layout({ children }) {
  const [isActiveCookies, setIsActiveCookies] = useState(false);

  return (
    <div className="flex flex-col min-h-screen text-gray-900">
      <PageHeader />

      <main>{children}</main>

      <Footer />

      <a
        href="https://web.whatsapp.com/send?phone=5547991342776&text=Gostaria%20de%20conhecer%20mais%20sobre%20o%20AtendeJa"
        rel="noopener noreferrer"
        target="_blank"
        className="wpp-icon"
      >
        <img src={Whatsapp} width={50} />
      </a>
      <CookieConfirmation
        isActiveCookies={isActiveCookies}
        setIsActiveCookies={setIsActiveCookies}
      />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
