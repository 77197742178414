import PropTypes from "prop-types";
import React from "react";
import InputMask from "react-input-mask";

export function Input({ register, label, name, mask, type, required, errors }) {
  return (
    <div>
      <label>
        {label} {required && <span className="text-green">*</span>}
      </label>
      <InputMask
        mask={mask}
        name={name}
        type={type}
        ref={register}
        maskPlaceholder={null}
      />
      {Object.keys(errors).indexOf(name) !== -1 && (
        <small className="text-red-700">Campo obrigatório!</small>
      )}
    </div>
  );
}

Input.propTypes = {
  errors: PropTypes.any,
  label: PropTypes.string,
  mask: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.any,
  required: PropTypes.bool,
  type: PropTypes.string,
};

export function Select({ register, label, name, options, className }) {
  return (
    <div className={className}>
      <label>{label}</label>
      <select name={name} ref={register}>
        {options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  register: PropTypes.any,
};
