import { Link } from "gatsby";
import React from "react";
import SoftinLogo from "../assets/icons/softin_logo.png";

function Footer() {
  return (
    <footer className="footer p-10">
      <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-12 md:mt-8">
        <div className="col-span-1 col-start-1">
          <h3 className="text-white mb-3">AtendeJá</h3>
          <Link to="/recursos">
            <p className="text-white text-base mb-2 navbar">Recursos</p>
          </Link>
          <Link to="/planos">
            <p className="text-white text-base mb-2 navbar">Planos e preços</p>
          </Link>
          <Link to="/contato">
            <p className="text-white text-base mb-2 navbar">Contato</p>
          </Link>
        </div>
        <div className="col-span-1 lg:col-start-2">
          <h3 className="text-white mb-3">Ajuda</h3>
          <a
            href="http://suporte.softin.com.br:443/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <p className="text-white text-base mb-2 navbar">Suporte</p>
          </a>
          <p className="text-white text-base mb-2">Central de ajuda</p>
        </div>
        <div className="col-span-1 xl:col-start-4">
          <h3 className="text-white mb-3">Contato</h3>
          <div className="flex mb-2">
            <box-icon name="phone" color="#fff" type="solid" />
            <p className="flex text-white text-base items-center ml-3">
              (47) 3437-3312
            </p>
          </div>
          <div className="flex mb-2">
            <box-icon name="whatsapp" type="logo" color="#fff" />
            <p className="flex text-white text-base items-center ml-3">
              (47) 99134-2776
            </p>
          </div>
          <div className="flex mb-2">
            <box-icon name="envelope" color="#fff" />
            <p className="flex text-white text-base items-center ml-3">
              comercial@atendeja.com.br
            </p>
          </div>
          <div className="flex">
            <a
              href="https://www.facebook.com/appatendeja"
              rel="noopener noreferrer"
              target="_blank"
            >
              <box-icon name="facebook" type="logo" color="#fff" />
            </a>
            <a
              className="ml-3"
              href="https://www.instagram.com/appatendeja/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <box-icon name="instagram" type="logo" color="#fff" />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-div text-white"></div>
      <div className="sm:flex text-white justify-between mt-12 mb-10">
        <p className="text-base">
          © 2021 AtendeJá. Todos os direitos reservados
        </p>
        <span className="inline-flex">
          <p className="text-base mr-4 sm:w-4/5 sm:text-right mt-10 sm:mt-0">
            Desenvolvido por{" "}
          </p>
          <img src={SoftinLogo} className="sm:w-1/5 h-2/5 w-2/5 mt-auto" />
        </span>
      </div>
    </footer>
  );
}

export default Footer;
