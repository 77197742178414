import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Button from "./Button";

export default function CookieConfirmation({
  isActiveCookies,
  setIsActiveCookies,
}) {
  useEffect(() => {
    if (getCookie("showConfirmation") === "") {
      setIsActiveCookies(true);
      setCookie("showConfirmation", 1, 1);
    }
    checkCookie();
  }, [isActiveCookies]);

  function setCookie(cname, cvalue, exdays) {
    const date = new Date();
    const timestampedDate = date.getTime() + exdays * 24 * 60 * 60 * 1000;
    const expires = `expires=${timestampedDate}`;
    document.cookie = `${cname}=${cvalue},${expires},path=/`;
  }

  function getCookie(cname) {
    const name = `${cname}=`;
    const docCookie = document.cookie.split(",");
    if (docCookie[0].includes(name)) {
      const cookieExpiration = docCookie[1].substr(8);
      return cookieExpiration;
    }
    return "";
  }

  function checkCookie() {
    const expirationDate = getCookie("showConfirmation");
    let actualDate = new Date();
    actualDate = actualDate.getTime();
    const expirationDateWithoutQuotes = Number(expirationDate);
    if (actualDate >= expirationDateWithoutQuotes) {
      setIsActiveCookies(true);
    }
    if (
      expirationDate !== "" &&
      actualDate &&
      actualDate >= expirationDateWithoutQuotes
    ) {
      setCookie("showConfirmation", 1, 1);
    }
  }

  return (
    <div className={isActiveCookies ? "cookie-confirmation flex" : "hidden"}>
      <span className="m-auto sm:flex text-center sm:text-left">
        <span className="text-white lg:mr-32 md:mr-16 mr-3">
          <b>
            Este site utiliza cookies para que você tenha uma melhor
            experiência.
          </b>
          <p>
            Para mais detalhes, acesse nossa{" "}
            <a
              href="https://app.gravittem.com/terms-of-services/"
              rel="noopener noreferrer"
              target="_blank"
              className="text-green"
            >
              política de privacidade
            </a>
            .
          </p>
        </span>
        <Button onClick={() => setIsActiveCookies(false)}>OK, ENTENDI</Button>
      </span>
    </div>
  );
}

CookieConfirmation.propTypes = {
  isActiveCookies: PropTypes.bool,
  setIsActiveCookies: PropTypes.func,
};
